import { debounce } from 'lodash';
import jQuery from 'jquery';
import * as bootstrap from 'bootstrap';

window.$ = jQuery; // workaround for https://github.com/parcel-bundler/parcel/issues/333

import instantsearch from 'instantsearch.js/es';
import {
    searchBox,
    pagination,
    refinementList,
    hits,
    stats,
    sortBy,
    hierarchicalMenu,
    rangeSlider,
    ratingMenu,
    toggleRefinement,
    hitsPerPage,
    clearRefinements,
    breadcrumb,
} from 'instantsearch.js/es/widgets';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';

let TYPESENSE_SERVER_CONFIG = {
    apiKey: process.env.TYPESENSE_SEARCH_ONLY_API_KEY, // Be sure to use an API key that only allows searches, in production
    nodes: [{
        host: process.env.TYPESENSE_HOST,
        port: process.env.TYPESENSE_PORT,
        protocol: process.env.TYPESENSE_PROTOCOL,
    }, ],
    connectionTimeoutSeconds: 5,
    numRetries: 8,
};

// [2, 3].forEach(i => {
//   if (process.env[`TYPESENSE_HOST_${i}`]) {
//     TYPESENSE_SERVER_CONFIG.nodes.push({
//       host: process.env[`TYPESENSE_HOST_${i}`],
//       port: process.env.TYPESENSE_PORT,
//       protocol: process.env.TYPESENSE_PROTOCOL,
//     });
//   }
// });

// Unfortunately, dynamic process.env keys don't work with parcel.js
// So need to enumerate each key one by one

if (process.env[`TYPESENSE_HOST_2`]) {
    TYPESENSE_SERVER_CONFIG.nodes.push({
        host: process.env[`TYPESENSE_HOST_2`],
        port: process.env.TYPESENSE_PORT,
        protocol: process.env.TYPESENSE_PROTOCOL,
    });
}

if (process.env[`TYPESENSE_HOST_3`]) {
    TYPESENSE_SERVER_CONFIG.nodes.push({
        host: process.env[`TYPESENSE_HOST_3`],
        port: process.env.TYPESENSE_PORT,
        protocol: process.env.TYPESENSE_PROTOCOL,
    });
}

if (process.env[`TYPESENSE_HOST_NEAREST`]) {
    TYPESENSE_SERVER_CONFIG['nearestNode'] = {
        host: process.env[`TYPESENSE_HOST_NEAREST`],
        port: process.env.TYPESENSE_PORT,
        protocol: process.env.TYPESENSE_PROTOCOL,
    };
}

const FACET_CONFIG = {
    //free_shipping: "non_numeric_attributes",
    //rating: "numeric_attributes",
    // price: "numeric_attributes",
    // hierarchicalCategories: "non_numeric_attributes",
    //price_range: "non_numeric_attributes",
    screen_size: 'numeric_attributes',
    brand: 'non_numeric_attributes',
    type: 'non_numeric_attributes',
    resolution: 'non_numeric_attributes',
    diagonal: 'non_numeric_attributes',
    memory: 'non_numeric_attributes',
    storage: 'non_numeric_attributes',
    colors: 'non_numeric_attributes',
    fidelity: 'non_numeric_attributes',
    phoneModels: 'non_numeric_attributes',
};

const nameToDisplay = {
    rating: 'Rating',
    screen_size: 'Screen Size (inches)',
    brand: 'Brand',
    price_range: 'Price Range ($)',
    type: 'Type',
    resolution: 'Resolution',
    diagonal: 'Diagonal (inches)',
    memory: 'Memory',
    storage: 'Storage',
    colors: 'Color',
    fidelity: 'Fidelity',
    phoneModels: 'Phone Model',
};

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: TYPESENSE_SERVER_CONFIG,
    additionalSearchParameters: {
        // The following parameters are directly passed to Typesense's search API endpoint.
        //  So you can pass any parameters supported by the search endpoint below.
        //  queryBy is required.
        query_by: 'name,categories,description',
        query_by_weights: '4,2,1',
        num_typos: 1,
        typo_tokens_threshold: 1,
        exclude_fields: 'vectors',
        // By default, Typesense approximates facet counts for performance.
        // Turning on exhaustive search will get you accurate facet counts, at the cost of a slight performance hit.
        exhaustive_search: true,
        // group_by: "categories",
        // group_limit: 1
        // pinned_hits: "23:2"
    },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;
const search = instantsearch({
    searchClient,
    indexName: 'catalog',
    routing: true,
    // searchParameters: {
    //     hitsPerPage: 12,
    //     facets: ['dynamic_attributes'],
    // },
    // onStateChange: function(h) {
    //     const helper = this.helper;
    //     // After changing the query, reset active facets
    //     helper.setState(helper.state.setDisjunctiveFacets(['']));
    //     helper.searchOnce({ hitsPerPage: 0 }).then(function(params) {
    //         const content = params.content;
    //         if (content.facets) {
    //             const newFacetsArr = content.getFacetValues('dynamic_attributes');
    //             console.log(content.facets);
    //             // Sort facets array by count DESC LIMIT 10 to use for refinementLists
    //             let facetsForRefinement = newFacetsArr
    //                 .sort((a, b) => {
    //                     return b.count - a.count;
    //                 }) // (a, b) => b.count - a.count
    //                 .slice(0, MAX_FACET_DISPLAYED)
    //                 .map((facetObj) => FACET_CONFIG[facetObj.name] + '.' + facetObj.name);

    //             // facets = facetsForRefinement; --> Keep global variable and just make widgets for all of the items in the array?
    //             // Update helper state to use newly retrieved facets
    //             helper.setState(helper.state.setDisjunctiveFacets(facetsForRefinement));
    //             h.setState(helper.state.setDisjunctiveFacets(facetsForRefinement));
    //         }
    //         h.search();
    //     });
    // },
});

// ============ Begin Widget Configuration
search.addWidgets([
    searchBox({
        container: '#searchbox',
        showSubmit: false,
        showReset: false,
        placeholder: 'Поиск по товарам... ',
        autofocus: false,
        cssClasses: {
            input: 'form-control form-control-sm border border-light text-dark',
            loadingIcon: 'stroke-primary',
        },
    }),
    pagination({
        container: '#pagination',
        cssClasses: {
            list: 'd-flex flex-row justify-content-end',
            item: 'px-2 d-block',
            link: 'text-decoration-none',
            disabledItem: 'text-muted',
            selectedItem: 'fw-bold text-primary',
        },
    }),
    refinementList({
        limit: 10,
        showMoreLimit: 50,
        container: '#brand-list',
        attribute: 'brand',
        searchable: true,
        searchablePlaceholder: 'Поиск производителей',
        showMore: true,
        sortBy: ['name:asc', 'count:desc'],
        cssClasses: {
            searchableInput: 'form-control form-control-sm form-control-secondary mb-2 border-light-2',
            searchableSubmit: 'd-none',
            searchableReset: 'd-none',
            showMore: 'btn btn-secondary btn-sm',
            list: 'list-unstyled',
            count: 'badge text-dark-2 ms-2',
            label: 'd-flex align-items-center',
            checkbox: 'me-2',
        },
    }),
    hierarchicalMenu({
        container: '#categories-hierarchical-menu',
        showParentLevel: true,
        rootPath: 'Catalog',
        attributes: [
            'categories.lvl0',
            'categories.lvl1',
            'categories.lvl2',
            'categories.lvl3',
        ],
        cssClasses: {
            showMore: 'btn btn-secondary btn-sm',
            list: 'list-unstyled',
            childList: 'ms-4',
            count: 'badge text-dark-2 ms-2',
            link: 'text-dark text-decoration-none',
            selectedItem: 'text-primary fw-bold',
            parentItem: 'text-dark fw-bold',
        },
    }),
    toggleRefinement({
        container: '#toggle-refinement',
        attribute: 'free_shipping',
        templates: {
            labelText: 'В наличие',
        },
        cssClasses: {
            label: 'd-flex align-items-center',
            checkbox: 'me-2',
        },
    }),
    rangeSlider({
        container: '#price-range-slider',
        attribute: 'price',
    }),
    ratingMenu({
        container: '#rating-menu',
        attribute: 'rating',
        cssClasses: {
            list: 'list-unstyled',
            link: 'text-decoration-none',
            starIcon: '',
            count: 'badge text-dark-2 ms-2',
            disabledItem: 'text-muted',
            selectedItem: 'fw-bold text-primary',
        },
    }),
    sortBy({
        container: '#sort-by',
        items: [
            { label: 'Популярности', value: 'catalog' },
            { label: 'Цене (возрастание)', value: 'catalog/sort/price:asc' },
            { label: 'Цене (убывание)', value: 'catalog/sort/price:desc' },
        ],
        cssClasses: {
            select: 'form-select form-select-sm border-none text-black',
        },
    }),
    hits({
        container: '#hits',
        templates: {
            item: `
        <div>
            <div class="row image-container">
                <div class="col-md d-flex align-items-end justify-content-center">
                    <img width="124" height="124" src="{{image}}" alt="{{name}}" />
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md">
                    <h5>{{#helpers.highlight}}{ "attribute": "name" }{{/helpers.highlight}}</h5>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md">
                  {{#helpers.highlight}}{ "attribute": "description" }{{/helpers.highlight}}
                </div>
            </div>

            <div class="row mt-auto">
              <div class="col-md">
                <div class="hit-price fw-bold mt-4">{{price}} рублей.</div>
                <div class="hit-rating">Рейтинг: {{rating}}/5</div>
              </div>
            </div>

            <div class="row mt-auto">
                <div class="col-md">
                  <a href="#" data-document-id="{{id}}" onclick="findSimilarProducts('{{id}}')">Похожие товары</a>
                  <a target="_blank" href="https://atri-energo.ru/catalog_v2/view/{{id}}">Перейти</a>
                </div>
            </div>
        </div>
      `,
        },
        cssClasses: {
            list: 'list-unstyled grid-container',
            item: 'd-flex flex-column search-result-card mb-1 me-1 p-3',
            loadMore: 'btn btn-primary mx-auto d-block mt-4',
            disabledLoadMore: 'btn btn-dark mx-auto d-block mt-4',
        },
    }),
    hitsPerPage({
        container: '#hits-per-page',
        items: [
            { label: '9 товаров на страницу', value: 9, default: true },
            { label: '18 товаров на страницу', value: 18 },
        ],
        cssClasses: {
            select: 'form-select form-select-sm border-none text-black',
        },
    }),
    stats({
        container: '#stats',
        templates: {
            text: `
      {{#hasNoResults}}Нет товаров{{/hasNoResults}}
      {{#hasOneResult}}1 товар{{/hasOneResult}}
      {{#hasManyResults}}{{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}} товаров{{/hasManyResults}}
      найдено за {{processingTimeMS}}ms
    `,
        },
        cssClasses: {
            text: 'small',
        },
    }),
    clearRefinements({
        container: '#clear-refinements',
        cssClasses: {
            button: 'btn btn-primary',
        },
        templates: {
            resetLabel({ hasRefinements }, { html }) {
                return html `<span>${hasRefinements ? 'Сбросить параметры поиска' : 'No refinements'}</span>`;
            },
        },
    }),
]);

window.sendEventDebounced = debounce((uiState) => {
    window.gtag('event', 'page_view', {
        page_path: window.location.pathname + window.location.search,
    });
}, 500);

search.use(() => ({
    onStateChange({ uiState }) {
        window.sendEventDebounced(uiState);
    },
    subscribe() {},
    unsubscribe() {},
}));

search.start();

window.findSimilarProducts = async function(productId) {
    const results = await typesenseInstantsearchAdapter.typesenseClient
        .collections('products')
        .documents()
        .search({
            q: '*',
            per_page: 4,
            vector_query: `vectors:([], id: ${productId})`,
        });
    console.log(results);

    const modalContent = results.hits.map((hit) => {
        return `
      <div class="p-3 m-3 border-1">
        <div class="row image-container">
            <div class="col-md d-flex align-items-end justify-content-center">

            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md">

            </div>
        </div>
      </div>
    `;
    });

    // const modalContent = results.hits.map((hit) => {
    //     return `
    //   <div class="p-3 m-3 border-1">
    //     <div class="row image-container">
    //         <div class="col-md d-flex align-items-end justify-content-center">
    //             <img src="${hit.document.image}" alt="${hit.document.name}" />
    //         </div>
    //     </div>
    //     <div class="row mt-5">
    //         <div class="col-md">
    //             ${hit.document.name}
    //         </div>
    //     </div>
    //   </div>
    // `;
    // });

    $('#similar-products-modal .modal-body .similar-products').html(modalContent);
    const modal = new bootstrap.Modal('#similar-products-modal', {});
    modal.show();
};